exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-commercial-js": () => import("./../../../src/pages/commercial.js" /* webpackChunkName: "component---src-pages-commercial-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-black-german-kitchen-new-build-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/black-german-kitchen-new-build/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-black-german-kitchen-new-build-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-black-handleless-kitchen-uxbridge-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/black-handleless-kitchen-uxbridge/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-black-handleless-kitchen-uxbridge-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-blue-kitchen-leicester-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/blue-kitchen-leicester/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-blue-kitchen-leicester-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-blue-kitchen-sawbridgeworth-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/blue-kitchen-sawbridgeworth/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-blue-kitchen-sawbridgeworth-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-german-handleless-kitchen-barnet-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/german-handleless-kitchen-barnet/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-german-handleless-kitchen-barnet-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-green-gloss-jhandle-northampton-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/green-gloss-jhandle-northampton/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-green-gloss-jhandle-northampton-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-grey-handleless-kitchen-bedford-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/grey-handleless-kitchen-bedford/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-grey-handleless-kitchen-bedford-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-housing-association-kitchen-budget-peterborough-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/housing-association-kitchen-budget-peterborough/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-housing-association-kitchen-budget-peterborough-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-new-build-kitchen-developers-luton-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/new-build-kitchen-developers-luton/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-new-build-kitchen-developers-luton-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-white-handleless-kitchen-new-build-flats-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/white-handleless-kitchen-new-build-flats/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-projects-white-handleless-kitchen-new-build-flats-index-mdx" */),
  "component---src-pages-quote-estimate-js": () => import("./../../../src/pages/quote-estimate.js" /* webpackChunkName: "component---src-pages-quote-estimate-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-residential-js": () => import("./../../../src/pages/residential.js" /* webpackChunkName: "component---src-pages-residential-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

